import Image from 'gatsby-image/withIEPolyfill';
import React from 'react';

export const swiperContent = (content) => {
  return content.map((slide) => (
    <div key={slide.full_image_url.localFile.title}>
      <Image
        fluid={slide.full_image_url.localFile.childImageSharp.fluid}
        className={'image'}
        objectFit={'contain'}
        objectPosition={'50% 50%'}
        alt={slide.full_image_url.localFile.title}
      />
    </div>
  ));
};

export const singleImageContent = (content) => {
  return (
    <div>
      <Image
        fluid={content.localFile.childImageSharp.fluid}
        className='image'
        objectFit='contain'
        objectPosition='50% 50%'
      />
    </div>
  );
};
