import React, { Fragment, useContext, useRef, useState } from 'react';
import Image from 'gatsby-image/withIEPolyfill';
import SwiperSlider from '../slider/swiper';
import { isBrowser, isMobileOnly, isTablet } from 'react-device-detect';
import { PageDetailsContext } from '../layouts/pagesWrapper';
import { swiperContent } from '../../helpers/swiper-images';
import './works.scss';

function WorksPost({ images, animation, workDescription }) {
  const { isClient } = useContext(PageDetailsContext);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [activePhoto, setActivePhoto] = useState(0);
  const imageRef = useRef();
  const tabletImageRef = useRef();
  const createIcon = (image) => {
    const fullscreen = document.createElement('i');
    fullscreen.classList.add('fas');
    fullscreen.classList.add('fa-expand-arrows-alt');
    fullscreen.classList.add('fullscreen');
    const leftPosition = image.current.imageRef.current.width - 36;
    const topPosition = image.current.imageRef.current.height - 39;
    fullscreen.style.left = leftPosition + 'px';
    fullscreen.style.top = topPosition + 'px';
    image.current.imageRef.current.after(fullscreen);
  };

  images &&
    images.sort((a, b) => {
      if (a.full_image_url.title < b.full_image_url.title) {
        return -1;
      }
      if (a.full_image_url.title > b.full_image_url.title) {
        return 1;
      }
      return 0;
    });

  return (
    <Fragment>
      <div className={'works-gallery'}>
        <Fragment>
          <div
            style={
              isClient && isBrowser
                ? { width: 'calc(45% - 10px)' }
                : { display: 'none' }
            }
          >
            <div className='active-image' onClick={() => setOpenLightbox(true)}>
              {animation ? (
                <img className='animation' src={animation} />
              ) : (
                <div>
                  <button className={'btn'}>
                    <Image
                      ref={imageRef}
                      fluid={
                        images[activePhoto]?.full_image_url.localFile?.childImageSharp.fluid
                      }
                      className={'image'}
                      objectFit={'contain'}
                      objectPosition={'50% 50%'}
                      onLoad={() => createIcon(imageRef)}
                    />
                  </button>
                </div>
              )}
            </div>
            <div dangerouslySetInnerHTML={{ __html: workDescription }} />
          </div>
          <div
            style={
              isClient && isTablet
                ? { width: 'calc(45vw - 25px)' }
                : { display: 'none' }
            }
          >
            <div className='active-image' onClick={() => setOpenLightbox(true)}>
              {animation ? (
                <img className='animation' src={animation} />
              ) : (
                <div>
                  <button className={'btn'}>
                    <Image
                      ref={tabletImageRef}
                      fluid={
                        images[activePhoto]?.full_image_url.localFile?.childImageSharp.fluid
                      }
                      className={'image'}
                      objectFit={'contain'}
                      objectPosition={'50% 50%'}
                      onLoad={() => createIcon(tabletImageRef)}
                    />
                  </button>
                </div>
              )}
            </div>
            <div dangerouslySetInnerHTML={{ __html: workDescription }} />
          </div>
          <div
            style={
              isClient && isMobileOnly ? { width: '100%' } : { display: 'none' }
            }
          >
            <div className='active-image'>
              {animation && (
                <div>
                  <img className='animation' src={animation} />
                  <div dangerouslySetInnerHTML={{ __html: workDescription }} />
                </div>
              )}
            </div>
          </div>
        </Fragment>
        {images && images.length > 1 && (
          <Fragment>
            <div
              style={
                isClient && isBrowser
                  ? {
                      width: 'calc(55% - 10px)',
                      marginLeft: '10px',
                    }
                  : { display: 'none' }
              }
            >
              <div className={'thumbnails'}>
                {images.map((image, index) => (
                  <div
                    className={'thumbnails__content'}
                    key={
                      image.full_image_url.localFile?.childImageSharp.fluid.src
                    }
                  >
                    <button
                      className={'btn thumbnails__content--button'}
                      onClick={() => setActivePhoto(index)}
                    >
                      <Image
                        fluid={
                          image.full_image_url.localFile?.childImageSharp.fluid
                        }
                        className={'image'}
                        objectFit={'cover'}
                        objectPosition={`50% 50%`}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div
              style={
                isClient && isTablet
                  ? {
                      width: 'calc(55vw - 25px)',
                      marginLeft: '10px',
                    }
                  : { display: 'none' }
              }
            >
              <div className={`thumbnails tablet`}>
                {images.map((image, index) => (
                  <div
                    className={`thumbnails__content`}
                    key={
                      image.full_image_url.localFile?.childImageSharp.fluid.src
                    }
                  >
                    <button
                      className={`btn thumbnails__content--button`}
                      onClick={() => setActivePhoto(index)}
                    >
                      <Image
                        fluid={
                          image.full_image_url.localFile?.childImageSharp.fluid
                        }
                        className={`image`}
                        objectFit={`cover`}
                        objectPosition={`50% 50%`}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        )}
        {images && images.length > 0 && (
          <div
            style={
              isClient && isMobileOnly
                ? { width: '100%', margin: '0' }
                : { display: 'none' }
            }
          >
            <div className='thumbnails mobile'>
              {images.map((image, index) => (
                <div
                  className={`thumbnails__content`}
                  key={image.full_image_url.localFile?.childImageSharp.fluid.src}
                >
                  <button
                    className={`btn thumbnails__content--button`}
                    onClick={() => {
                      setActivePhoto(index);
                      setOpenLightbox(true);
                    }}
                  >
                    <Image
                      fluid={
                        image.full_image_url.localFile?.childImageSharp.fluid
                      }
                      className={`image`}
                      objectFit={`cover`}
                      objectPosition={`50% 50%`}
                    />
                  </button>
                </div>
              ))}
            </div>
            <div
              style={{ marginTop: '25px' }}
              dangerouslySetInnerHTML={{ __html: workDescription }}
            />
          </div>
        )}
      </div>
      {openLightbox && (
        <SwiperSlider
          images={swiperContent(images)}
          index={activePhoto}
          onClose={() => setOpenLightbox(false)}
        />
      )}
    </Fragment>
  );
}

export default WorksPost;
