import React, { Component, Fragment } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.min.css';
import './lightbox.scss';

class SwiperSlider extends Component {
  constructor(props) {
    super(props);
    this.options = {
      autoplay: false,
      initialSlide: props.index,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      loop: false,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };
    this.state = {
      selectedImage: this.props.index,
    };
  }

  componentDidMount() {
    if (document) {
      const swiperContainer = document.querySelector('.swiper-container');
      if (swiperContainer) {
        swiperContainer.addEventListener('click', (e) =>
          this.clickOutsideSlide(e),
        );
      }
    }
  }

  componentWillUnmount() {
    if (document) {
      const swiperContainer = document.querySelector('.swiper-container');
      if (swiperContainer) {
        swiperContainer.removeEventListener('click', (e) =>
          this.clickOutsideSlide(e),
        );
      }
    }
  }

  clickOutsideSlide = (e) => {
    if (document) {
      const nextBtn = document.querySelector(
        '.swiper-container .swiper-button-next',
      );
      const prevBtn = document.querySelector(
        '.swiper-container .swiper-button-prev',
      );
      const slides = document.querySelectorAll(
        '.swiper-container .swiper-wrapper .swiper-slide-active .gatsby-image-wrapper img',
      );
      const insideElement = [...slides].includes(e.target);
      if (
        e.target.id !== 'closeBtn' &&
        !insideElement &&
        e.target !== nextBtn &&
        e.target !== prevBtn
      ) {
        this.props.onClose();
      }
    }
  };

  render() {
    const { images, onClose } = this.props;
    return (
      <Fragment>
        <button id='closeBtn' className='btn close-btn' onClick={onClose}>
          &times;
        </button>
        <Swiper {...this.options}>{images}</Swiper>
      </Fragment>
    );
  }
}

export default SwiperSlider;
