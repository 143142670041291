import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layouts/layout';
import WorksPost from '../../components/works/works-post';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './works.scss';

export default ({ pageContext: { node, prev, next } }) => {
  return (
    <div>
      <Layout>
        <div className={'works__navigation'}>
          {prev && (
            <Link to={`/works/${prev.slug}`} className={'btn btn-back'}>
              <FontAwesomeIcon
                className={'btn-back__icon'}
                icon={faAngleLeft}
              />
              &nbsp;
              <span className={'btn-back__text'}>Previous&nbsp;work</span>
            </Link>
          )}
          {next && (
            <Link to={`/works/${next.slug}`} className={'btn btn-next'}>
              <span className={'btn-next__text'}>Next&nbsp;work</span>&nbsp;
              <FontAwesomeIcon
                className={'btn-next__icon'}
                icon={faAngleRight}
              />
              &nbsp;
            </Link>
          )}
        </div>
        {node.acf.animation?.localFile ? (
          <WorksPost
            animation={node.acf.animation.localFile.publicURL}
            workDescription={node.content}
          />
        ) : (
          <WorksPost
            images={node.acf_photo_gallery}
            workDescription={node.content}
          />
        )}
      </Layout>
    </div>
  );
};
